html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}
